import { useFormStore } from '@/store/forms.store'

import { scrollTo } from '@/utils/helpers'
import type { HubSpotFormId } from '@/types'

interface Options {
  headers?: {}
  headerOptions?: {
    headers?: {}
  }
  thankYouRoute?: string
  formSelector?: string
  offset?: number
}

const ALLOWED_URL_PATTERNS = [
  /^https:\/\/api\.hsforms\.com\/submissions\/v3\/integration\/submit\/5731513\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
] as RegExp[]

const isValidUrl = (url: string) => {
  return ALLOWED_URL_PATTERNS.some((pattern: RegExp) => pattern.test(url))
}
const getGenericFormUrl = (id: HubSpotFormId) => {
  return `https://api.hsforms.com/submissions/v3/integration/submit/5731513/${id}`
}

const useSendData = () => {
  const router = useRouter()
  const formStore = useFormStore()

  const isLoading = ref(false)
  const isFailed = ref(false)
  const isSuccessfullySent = ref(false)

  let urlParams: URLSearchParams | null = null

  onMounted(() => {
    urlParams = new URLSearchParams(window.location.search)
  })

  const onSuccess = (thankYouRoute: string) => {
    if (thankYouRoute) {
      router.push(thankYouRoute)
    }
    isSuccessfullySent.value = true
    isLoading.value = false
  }
  const onError = () => {
    isLoading.value = false
    isFailed.value = true
  }

  const scrollToError = (formSelector = '#form-wrapper', offset = 100) => {
    const errorElement = document.getElementsByClassName('input--error')[0]
    if (errorElement) {
      scrollTo('.input--error', 100)
      return
    }
    isFailed.value = true
    scrollTo(formSelector, offset)
  }

  const defaultHeaders = {
    'Content-Type': 'application/json; charset=UTF-8',
  }

  const sendFormData = async (
    url = '',
    formData = {},
    options: Options = {
      thankYouRoute: '/thank-you',
      formSelector: '#form-section',
      offset: 0,
    },
  ) => {
    isLoading.value = true

    if (!isValidUrl(url)) {
      throw new Error('Invalid URL')
    }

    const request = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
      },
      body: JSON.stringify(formData),
    })

    if (request.status === 200 || request.status === 201) {
      const data = await request.json()

      formStore.SET_SUCCESS_MESSAGE(data.inlineMessage)
      onSuccess(options.thankYouRoute)
      return request
    } else {
      onError()
      scrollToError(options.formSelector, options.offset)
      return request
    }
  }

  const sendGtmForm = async (
    url = '',
    formData = {},
    options: Options = {
      headerOptions: {
        headers: defaultHeaders,
      },
    },
  ) => {
    try {
      await fetch(url, {
        method: 'POST',
        headers: {
          ...options.headerOptions?.headers,
        },
        body: JSON.stringify(formData),
      })
    } catch (err) {
      if (err instanceof Error) {
        console.error(err.message)
      }
    }
  }

  return {
    isLoading,
    isFailed,
    isSuccessfullySent,
    scrollToError,
    sendGtmForm,
    sendFormData,
    getGenericFormUrl,
  }
}
export default useSendData
